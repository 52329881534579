
$(function(){
	$('#b-accueil').hide();
	
	$.getJSON('contents/php/JSON_table.php?s=id_actualite as i, accueil_titre as t FROM actualite WHERE accueil_ordre>0 ORDER BY accueil_ordre')
	.done(function(data){
		if(data.length == 0) return;
		$.each(data, function(n,v){
			$('#actualites').append('<div class="article" lid="'+v.i+'">'+v.t+'</div>');
		});
		$('#actualites').show();
	})
	
	$('#actualites').on('click', '.article', function(){
		goto('actualite.php?article='+$(this).attr('lid'));
	})
});
